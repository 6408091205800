jQuery(document).ready(function($) {
   $('#on-topnav__mobile-menu').click(function() {
      $('#on-topnav').toggleClass('open');
   });

   $('.on-topnav__item').each(function() {
      $(this).click(function() {
         if (!$(this).hasClass('on-topnav__item--has-children')) {
            $('#on-topnav').removeClass('open');
         }
      });
   });

   $('.on-topnav__submenu-button').click(function() {
      const clickedSubmenuButtonId = $(this)[0].id;
      const submenuIndex = clickedSubmenuButtonId.slice(('on-topnav__submenu-button-').length);
      const submenu = $(`#on-topnav__submenu-${submenuIndex}`);

      if (submenu.hasClass('d-block')) {
         $(this).removeClass('submenu-open')
         submenu.removeClass('d-block');
      }
      else {
         $(this).addClass('submenu-open');
         submenu.addClass('d-block');
      }
   });
});