/** ————————————————————————————————————————————————————————————————
 *  Scrollbar fix for vw
 *  ——————————————————————————————————————————————————————————————*/
function handleWindow() {
    let body = document.querySelector('body');

    if (window.innerWidth > body.clientWidth + 5) {
        body.setAttribute('style', '--scroll-bar: ' + (window.innerWidth - body.clientWidth) + 'px');
    } else {
        body.classList.remove('has-scrollbar');
    }
}

window.addEventListener('resize', handleWindow);
window.addEventListener('load', handleWindow);